import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import {  tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LivroService {

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  getAllLivros() {

    const url = `${environment.API}livros/getall`;

    return this.http.get(url);

  }

  getAllCategorias() {

    const url = `${environment.API}livros/categorias/todas`;

    return this.http.get(url);

  }

  getLivroDetail(id: number) {

    const url = `${environment.API}livros/getbyid/${id}`;

    return this.http.get(url);
  }

  searchLivro(livro: string) {

    const url = `${environment.API}livros/buscar/${livro}`;

    return this.http.get(url);
  }

  getLivroUser() {

    const iduser = this.auth.getUserLogged();
    const url = `${environment.API}livros/obtertodos/${iduser}`;

    return this.http.get(url);
  }

  getLivrosByCategoria(id: any) {

    const iduser = this.auth.getUserLogged();
    const url = `${environment.API}livros/categoria/${id}`;
    return this.http.get(url);
  }



}
